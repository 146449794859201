let showAlertFn: (message: string) => void = () => {};

export const alertService = {
  init: (fn: (message: string) => void) => {
    showAlertFn = fn;
  },
  show: (message: string) => {
    showAlertFn(message);
  },
};
