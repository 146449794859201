import { ENDPOINTS } from 'shared/constants';
import { httpClientAuthorized } from '../httpClient';
import {
  AppUser,
  withdrawData,
  stakeData,
  bakeryHistoryResponse,
  announceHistoryResponse,
  userHistoryResponse,
  UserHistoryType,
} from 'shared/types';

export const unlockBakery = async () => {
  const res = await httpClientAuthorized.put<AppUser>(ENDPOINTS.UNLOCK_BAKERY);
  return res.data;
};

export const bakeryConvert = async (coins: number) => {
  const res = await httpClientAuthorized.put<AppUser>(ENDPOINTS.BAKERY_CONVERT, { coins });
  return res.data;
};

export const bakeryWithdraw = async (tokens: number) => {
  const res = await httpClientAuthorized.put<withdrawData>(ENDPOINTS.BAKERY_WITHDRAW, { tokens });
  return res.data;
};

export const bakeryStake = async (tokens: number) => {
  const res = await httpClientAuthorized.put<stakeData>(ENDPOINTS.BAKERY_STAKE, { tokens });
  return res.data;
};

export const getBakeryHistory = async (offset: number = 0, limit: number = 10) => {
  const res = await httpClientAuthorized.get<bakeryHistoryResponse>(
    ENDPOINTS.BAKERY_HISTORY(limit, offset),
  );
  return res.data;
};

export const getAnnounceHistory = async (offset: number = 0, limit: number = 1) => {
  const res = await httpClientAuthorized.get<announceHistoryResponse>(
    ENDPOINTS.ANNOUNCE_HISTORY(limit, offset),
  );
  return res.data;
};

export const getUserHistory = async (
  wallet: string,
  offset: number = 0,
  limit: number = 10,
  type?: UserHistoryType,
) => {
  const res = await httpClientAuthorized.get<userHistoryResponse>(
    ENDPOINTS.GET_USER_HISTORY(wallet, limit, offset, type),
  );
  return res.data;
};
