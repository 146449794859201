import styles from './style.module.scss';
import { useTranslation, Trans } from 'react-i18next';
import { UIButton, UIModal, UIModalProps, UITypography } from 'shared/ui';
import BobeAdsError from 'assets/images/bobe_ads_error.webp';
import BronzeCoinIcon from 'assets/images/bronze-coin.png';
import { skipWaitingOnce, buyBonusLimitOnce } from 'shared/api';
import { useState } from 'react';
import { useGameStore, useGlobalStore } from 'shared/store';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'shared/constants';
import { useCheckBobeBalance } from 'shared/hooks/use-check-bobe-balance';

type Variant = 'tubo_boost' | 'daily_limit_increase' | 'start_session_instantly';

type TurboBoostModalProps = Omit<UIModalProps, 'children'> & {
  variant?: Variant;
  onComplete?: () => void;
};

export const TurboBoostModal = ({
  visible,
  onClose,
  onComplete = () => {},
  variant = 'tubo_boost',
}: TurboBoostModalProps) => {
  const { checkBobeBalance, openNoBobeTokensModal } = useCheckBobeBalance();
  const updateUser = useGlobalStore((state) => state.updateUser);
  const [loading, setLoading] = useState(false);
  const { skip_waiting_once_price, increase_bonus_limit_once_price } = useGameStore(
    (state) => state.settings,
  );
  const nav = useNavigate();
  const setGameReady = useGameStore((state) => state.setGameReady);

  const variants = {
    daily_limit_increase: {
      price: increase_bonus_limit_once_price,
      handler: buyBonusLimitOnce,
    },
    start_session_instantly: {
      price: skip_waiting_once_price,
      handler: () =>
        skipWaitingOnce().then((user) => {
          setGameReady(true);
          nav(ROUTES.SESSION, { replace: true });
          return user;
        }),
    },
    tubo_boost: {
      price: skip_waiting_once_price,
      handler: skipWaitingOnce,
    },
  };

  const { handler, price } = variants[variant];

  const handleComplete = () => {
    if (!checkBobeBalance(price)) {
      onClose();
      openNoBobeTokensModal();
      return;
    }

    if (loading) return;

    setLoading(true);
    handler()
      .then((user) => {
        onComplete();
        onClose();
        updateUser(user);
        return;
      })
      .finally(() => setLoading(false));
  };

  const CurrentModal = variant === 'tubo_boost' ? DefaultTurboBoostModal : NoAdsTurboBoostModal;

  return (
    <CurrentModal
      price={Number(price)}
      visible={visible}
      loading={loading}
      onClick={handleComplete}
      onClose={onClose}
    />
  );
};

type DefaultTurboBoostModalProps = Omit<UIModalProps, 'children'> & {
  loading: boolean;
  price: number;
  onClick: () => void;
};

const DefaultTurboBoostModal = ({
  visible,
  onClose,
  onClick,
  loading,
  price,
}: DefaultTurboBoostModalProps) => {
  const { t } = useTranslation();

  return (
    <UIModal visible={visible} onClose={onClose} detent="content-height">
      <div className={styles.wrapper}>
        <img className={styles.img} src="/rocket.webp" />
        <UITypography className={styles.title} variant="H1">
          <Trans
            defaults="turbo_speed_modal.default_description"
            values={{ turbo_speed: `«${t('common.turbo_speed')}»` }}
            components={{ br: <br></br>, span: <span className={styles._highlight}></span> }}
          />
        </UITypography>

        <UIButton
          fullwidth
          className={styles.btn}
          onClick={onClick}
          loading={loading}
          disabled={loading}
        >
          {t('common.activate_for')} {price} <img src={BronzeCoinIcon} width={24} height={24} />
        </UIButton>
      </div>
    </UIModal>
  );
};

type NoAdsTurboBoostModalProps = Omit<UIModalProps, 'children'> & {
  loading: boolean;
  price: number;
  onClick: () => void;
};

const NoAdsTurboBoostModal = ({
  visible,
  onClose,
  onClick,
  loading,
  price,
}: NoAdsTurboBoostModalProps) => {
  const { t } = useTranslation();

  return (
    <UIModal visible={visible} onClose={onClose} detent="content-height">
      <div className={styles.wrapper}>
        <img className={styles.bobe_img} src={BobeAdsError} />
        <UITypography
          className={styles.title}
          variant="Body"
          style={{
            fontSize: 20,
            lineHeight: 1.25,
          }}
          fontWeight={'extraBold'}
          component="p"
        >
          <div className={styles._highlight}>{t('common.oops')}!...</div>
          <Trans
            defaults="turbo_speed_modal.no_ads_description"
            values={{ secret_pass: `«${t('turbo_speed_modal.secret_pass')}»` }}
            components={{ br: <br></br>, span: <span className={styles._highlight}></span> }}
          />
        </UITypography>

        <UIButton
          className={styles.btn}
          onClick={onClick}
          loading={loading}
          disabled={loading}
          fullwidth
        >
          {t('common.activate_for')} {price} <img src={BronzeCoinIcon} width={24} height={24} />
        </UIButton>
      </div>
    </UIModal>
  );
};
