import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import { COMMON_PRELOAD_ASSETS, TOKEN_SHORTCUT } from 'shared/constants';
import { UITypography, UIButton } from 'shared/ui';
import { ReactComponent as DollarCircleIcon } from 'assets/icons/dollar-circle.svg';
import { useGlobalStore } from 'shared/store';
import stars from 'assets/images/stars2.png';
import { formatLargeNumber } from 'shared/utils';
import { AnnounceHistoryEntity, UserHistoryEntity, UserHistoryType } from 'shared/types';
import { Spinner } from 'components/loading/spinner';
import { getAnnounceHistory, getUserHistory } from 'shared/api';
import {
  AnnouncementCard,
  UserHistoryCard,
  USDT_TOKEN,
  MAIN_GAME_TOKEN,
  AmountWithTokenData,
  SuccessClaimRewardsModal,
} from 'components';

export const MainTokenBalance = () => {
  const { t } = useTranslation();
  const user = useGlobalStore((state) => state.user);

  return (
    <div className={styles.root}>
      <UITypography
        iconLeft={<DollarCircleIcon />}
      >{`${t('common.game_balance')} ${TOKEN_SHORTCUT}`}</UITypography>

      <div className={styles.balanceRoot}>
        <UITypography
          variant="H1"
          component="h2"
        >{`${formatLargeNumber(user.tokens, 8)} ${TOKEN_SHORTCUT}`}</UITypography>
        <img src={COMMON_PRELOAD_ASSETS.bobeBakery} alt="" className={styles.bobe} />
        <img src={stars} alt="" className={styles.stars} />
      </div>
    </div>
  );
};

import { useState, useEffect } from 'react';
import { formatUnits } from 'ethers';
import { Contract, JsonRpcProvider } from 'ethers';
import CLAIM_GAME_ABI from 'shared/abi/claim-game-tokens.json';

type StakingStats = {
  stakedTokens: string;
  pendingRewards: string;
  totalClaimedRewards: string;
  rewardsPerSecond: string;
  userStakeAmounts: bigint[];
  userStakeTimes: bigint[];
  unlockableAmount: string;
  totalUnstaked: string;
  unstakePeriod: number;
  tokenBalance: string;
};

export const useGlobalStats = () => {
  const [stats, setStats] = useState({
    totalStaked: '0',
    totalStakers: '0',
    activeStakers: '0',
    totalDistributed: '0',
    rewardRatePerToken: '0',
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const provider = new JsonRpcProvider('https://bsc-dataseed.binance.org/'); // TODO: temporary
        // const provider = new JsonRpcProvider('https://data-seed-prebsc-1-s1.binance.org:8545/');
        const contract = new Contract(
          process.env.REACT_APP_CLAIM_CONTRACT_ADDRESS as string,
          CLAIM_GAME_ABI,
          provider,
        );

        const rawStats = await contract.getGlobalStats();
        setStats({
          totalStaked: formatUnits(rawStats[0], MAIN_GAME_TOKEN.decimals),
          totalStakers: rawStats[1].toString(),
          activeStakers: rawStats[2].toString(),
          totalDistributed: formatUnits(rawStats[3], USDT_TOKEN.decimals),
          rewardRatePerToken: formatUnits(rawStats[4], USDT_TOKEN.decimals),
        });
      } catch (error) {
        console.error('Error fetching global stats:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchStats();
    const interval = setInterval(fetchStats, 5000);
    return () => clearInterval(interval);
  }, []);

  return { ...stats, isLoading };
};

export const useStakingStats = () => {
  const user = useGlobalStore((state) => state.user);
  const [stats, setStats] = useState<StakingStats>({
    stakedTokens: '0',
    pendingRewards: '0',
    totalClaimedRewards: '0',
    rewardsPerSecond: '0',
    userStakeAmounts: [],
    userStakeTimes: [],
    unlockableAmount: '0',
    totalUnstaked: '0',
    unstakePeriod: 0,
    tokenBalance: '0',
  });
  const [isLoading, setIsLoading] = useState(true);

  const fetchStats = async () => {
    if (!user.wallet) {
      setIsLoading(false);
      return;
    }

    try {
      const provider = new JsonRpcProvider('https://bsc-dataseed.binance.org/'); // TODO: trmporary
      // const provider = new JsonRpcProvider('https://data-seed-prebsc-1-s1.binance.org:8545/');
      const contract = new Contract(
        process.env.REACT_APP_CLAIM_CONTRACT_ADDRESS as string,
        CLAIM_GAME_ABI,
        provider,
      );

      const rawStats = await contract.getUserStats(user.wallet);
      // console.log('stats: ', rawStats);

      setStats({
        stakedTokens: formatUnits(rawStats[0], MAIN_GAME_TOKEN.decimals),
        pendingRewards: formatUnits(rawStats[1], MAIN_GAME_TOKEN.decimals),
        totalClaimedRewards: formatUnits(rawStats[2], MAIN_GAME_TOKEN.decimals),
        rewardsPerSecond: formatUnits(rawStats[3], MAIN_GAME_TOKEN.decimals),
        userStakeAmounts: rawStats[4],
        userStakeTimes: rawStats[5],
        unlockableAmount: formatUnits(rawStats[6], MAIN_GAME_TOKEN.decimals),
        totalUnstaked: formatUnits(rawStats[7], MAIN_GAME_TOKEN.decimals),
        unstakePeriod: Number(rawStats[8]),
        tokenBalance: formatUnits(rawStats[9], MAIN_GAME_TOKEN.decimals),
      });
    } catch (error) {
      console.error('Error fetching staking stats:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchStats();
    const interval = setInterval(fetchStats, 5000);
    return () => clearInterval(interval);
  }, [user.wallet]);

  return {
    ...stats,
    isLoading,
    refetchStats: fetchStats,
  };
};

export const BakeryGlobalStats = () => {
  const { t } = useTranslation();
  const {
    totalStaked,
    totalStakers,
    activeStakers,
    totalDistributed,
    rewardRatePerToken,
    isLoading,
  } = useGlobalStats();

  return (
    <div className={styles.statsContainer}>
      <UITypography
        iconLeft={<DollarCircleIcon />}
      >{`${t('bakery.overview')} ${TOKEN_SHORTCUT}`}</UITypography>

      <div className={styles.statsBlock}>
        <UITypography>{t('bakery.total_value_locked')}</UITypography>
        <UITypography>{t('bakery.total_value_by', { count: Number(activeStakers) })}</UITypography>
        <AmountWithTokenData amount={totalStaked} token={MAIN_GAME_TOKEN} />
      </div>

      <div className={styles.statsBlock}>
        <UITypography>{t('bakery.total_baking_earnings')}</UITypography>
        <UITypography>{t('bakery.total_baking_by', { count: Number(totalStakers) })}</UITypography>
        <AmountWithTokenData amount={totalDistributed} token={USDT_TOKEN} />
      </div>

      <div className={styles.statsBlock}>
        <UITypography>{t('bakery.total_rate')}</UITypography>
        <UITypography>{t('bakery.total_rate_label')}</UITypography>
        <AmountWithTokenData amount={rewardRatePerToken} token={USDT_TOKEN} />
      </div>
    </div>
  );
};

export const BakeryUserStats = () => {
  const { t } = useTranslation();
  const user = useGlobalStore((state) => state.user);
  const setUnstakePeriod = useGlobalStore((state) => state.setUnstakePeriod);
  const setTokenBalance = useGlobalStore((state) => state.setTokenBalance);
  const setUnlockableAmount = useGlobalStore((state) => state.setUnlockableAmount);

  const [openRequestSuccessModal, setOpenRequestSuccessModal] = useState(false);

  const {
    stakedTokens,
    pendingRewards,
    totalClaimedRewards,
    unlockableAmount,
    unstakePeriod,
    tokenBalance,
    isLoading,
  } = useStakingStats();

  useEffect(() => {
    if (unstakePeriod) {
      setUnstakePeriod(unstakePeriod);
    }
  }, [unstakePeriod, setUnstakePeriod]);

  useEffect(() => {
    if (tokenBalance) {
      setTokenBalance(tokenBalance);
    }
  }, [tokenBalance, setTokenBalance]);

  useEffect(() => {
    if (unlockableAmount) {
      setUnlockableAmount(unlockableAmount);
    }
  }, [unlockableAmount, setUnlockableAmount]);

  return (
    <>
      <div className={styles.statsContainer}>
        <UITypography
          iconLeft={<DollarCircleIcon />}
        >{`${t('bakery.my_position')} ${TOKEN_SHORTCUT}`}</UITypography>

        {/* <div className={styles.statsBlock}>
        <UITypography>{t('bakery.token_balance')}</UITypography>
        <AmountWithTokenData amount={tokenBalance} token={MAIN_GAME_TOKEN} />
      </div> */}

        <div className={styles.statsBlock}>
          <UITypography>{t('bakery.user_value_locked')}</UITypography>
          <AmountWithTokenData amount={stakedTokens} token={MAIN_GAME_TOKEN} />
        </div>

        <div className={styles.statsBlock}>
          <UITypography>{t('bakery.user_baking_earnings')}</UITypography>
          <AmountWithTokenData amount={totalClaimedRewards} token={USDT_TOKEN} />
        </div>

        <div className={styles.statsBlock}>
          <UITypography>{t('bakery.user_unclaimed_rewards')}</UITypography>
          <AmountWithTokenData amount={pendingRewards} token={USDT_TOKEN} />

          <div className={styles.claimButton}>
            <UIButton
              fullwidth
              onClick={() => {
                setOpenRequestSuccessModal(true);
              }}
              disabled={!user.wallet || +pendingRewards === 0}
            >
              {t('bakery.claim_rewards')}
            </UIButton>
          </div>
        </div>
      </div>

      <SuccessClaimRewardsModal
        visible={openRequestSuccessModal}
        onClose={() => {
          setOpenRequestSuccessModal(false);
        }}
      />
    </>
  );
};

export const DailyBenefitHistory = () => {
  const { t } = useTranslation();
  const [announcements, setAnnouncements] = useState<AnnounceHistoryEntity[]>([]);
  const [loadingHistory, setLoadingHistory] = useState(true);
  const [alreadyLoaded, setAlreadyLoaded] = useState(false);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const LIMIT = 1;

  const loadHistory = async (loadMore = false) => {
    setLoadingHistory(true);
    try {
      const response = await getAnnounceHistory(offset, LIMIT);
      // console.log('getAnnounceHistory: ', response);
      setAnnouncements((prev) =>
        loadMore ? [...prev, ...response.transactions] : response.transactions,
      );
      setOffset((prev) => prev + LIMIT);
      setHasMore(response.pagination.has_more);
    } catch (error) {
      console.error('Failed to load announcements:', error);
    } finally {
      setLoadingHistory(false);
      setAlreadyLoaded(true);
    }
  };

  useEffect(() => {
    loadHistory();
  }, []);

  const handleLoadMore = () => {
    loadHistory(true);
  };

  return (
    <div className={styles.statsContainer}>
      <UITypography
        iconLeft={<DollarCircleIcon />}
      >{`${t('bakery.announce_history')}`}</UITypography>

      <div className={styles.history}>
        {!alreadyLoaded ? (
          <div className={styles.loading}>
            <Spinner borderWidth={2} size={16} color="inherit" />
          </div>
        ) : announcements.length === 0 ? (
          <div className={styles.empty}>
            <UITypography>{t('bakery.no_announcements')}</UITypography>
          </div>
        ) : (
          <>
            {announcements.map((announcement) => (
              <AnnouncementCard key={announcement.tx_hash} announcement={announcement} />
            ))}

            {hasMore && (
              <div className={styles.loading}>
                <UIButton
                  onClick={handleLoadMore}
                  fullwidth
                  variant="secondary"
                  className={styles.loadMoreButton}
                  loading={loadingHistory}
                  disabled={loadingHistory}
                >
                  {t('common.load_more')}
                </UIButton>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export const UserWalletHistory = () => {
  const { t } = useTranslation();
  const user = useGlobalStore((state) => state.user);
  // const [activeTab, setActiveTab] = useState<UserHistoryType | ''>('');
  const [activeTab, setActiveTab] = useState<UserHistoryType | ''>('claim_rewards');
  const [transactions, setTransactions] = useState<UserHistoryEntity[]>([]);
  const [loadingHistory, setLoadingHistory] = useState(true);
  const [alreadyLoaded, setAlreadyLoaded] = useState(false);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const LIMIT = 5;

  const tabs: Array<{ value: '' | UserHistoryType; label: string }> = [
    // { value: '', label: t('bakery.all') },
    // { value: 'stake', label: t('bakery.tab-bake') },
    // { value: 'unstake', label: t('bakery.tab-unbake') },
    { value: 'claim_rewards', label: t('bakery.tab-rewards') },
    // { value: 'claim_tokens', label: t('bakery.claim_tokens') },
  ];

  const loadHistory = async (params: {
    type?: UserHistoryType | '';
    currentOffset: number;
    isLoadMore?: boolean;
  }) => {
    if (!user.wallet) return;

    setLoadingHistory(true);
    try {
      const response = await getUserHistory(
        user.wallet,
        params.currentOffset,
        LIMIT,
        params.type || undefined,
      );

      setTransactions((prev) => {
        const newState = params.isLoadMore
          ? [...prev, ...response.transactions]
          : response.transactions;

        return newState;
      });

      setOffset(params.currentOffset + LIMIT);
      setHasMore(response.pagination.has_more);
    } catch (error) {
      console.error('Failed to load history:', error);
    } finally {
      setLoadingHistory(false);
      setAlreadyLoaded(true);
    }
  };

  // Initial load when wallet changes
  useEffect(() => {
    if (user.wallet) {
      loadHistory({ type: 'claim_rewards', currentOffset: 0 });
    }
  }, [user.wallet]);

  // Handle tab changes
  const handleTabChange = (tabValue: UserHistoryType | '') => {
    setActiveTab(tabValue);
    setTransactions([]);
    setOffset(0);
    setHasMore(true);
    setAlreadyLoaded(false);

    loadHistory({ type: tabValue, currentOffset: 0 });
  };

  return (
    <div className={styles.root}>
      <UITypography iconLeft={<DollarCircleIcon />}>{t('bakery.wallet_history')}</UITypography>

      <div className={styles.tabs}>
        {tabs.map((tab) => (
          <UIButton
            key={tab.value}
            onClick={() => handleTabChange(tab.value)}
            variant={activeTab === tab.value ? 'primary' : 'secondary'}
            className={styles.tabButton}
          >
            {tab.label}
          </UIButton>
        ))}
      </div>

      <div className={styles.history}>
        {!alreadyLoaded ? (
          <div className={styles.loading}>
            <Spinner borderWidth={2} size={16} color="inherit" />
          </div>
        ) : transactions.length === 0 ? (
          <div className={styles.empty}>
            <UITypography>{t('bakery.no_transactions')}</UITypography>
          </div>
        ) : (
          <>
            {transactions.map((transaction) => (
              <UserHistoryCard key={transaction.tx_hash} transaction={transaction} />
            ))}

            {hasMore && (
              <div className={styles.loadingMoreButton}>
                <UIButton
                  onClick={() => {
                    loadHistory({
                      currentOffset: offset,
                      isLoadMore: true,
                    });
                  }}
                  fullwidth
                  variant="secondary"
                  className={styles.loadMoreButton}
                  loading={loadingHistory}
                  disabled={loadingHistory}
                >
                  {t('common.load_more')}
                </UIButton>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
