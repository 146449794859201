import styles from './style.module.scss';
import { UIButton, UITypography } from 'shared/ui';
import { useGlobalStore } from 'shared/store';
import { useWebApp } from '@vkruglikov/react-telegram-web-app';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddPromoRefLinkModal } from 'components';
import { TickCircle } from 'assets/icons/tick-circle';
import iconImage from 'assets/images/rocket-icon.webp';
import { useAlert } from 'shared/ui/components/alert/alert';

export const PromoRefLink = () => {
  const { t } = useTranslation();
  const { showAlert } = useAlert();
  const user = useGlobalStore((state) => state.user);

  const [visibleAddLinkModal, setVisibleAddLinkModal] = useState(false);

  const openAddLinkModal = async () => {
    try {
      setVisibleAddLinkModal(true);
    } catch (error) {
      console.log(error);
      showAlert(t('alerts.something_went_wrong'));
    }
  };

  return (
    <div>
      {user.promo_link_for_referrals ? (
        <div className={styles.walletConnectedRoot} onClick={openAddLinkModal}>
          <div className={styles.walletConnectedContent}>
            <img src={iconImage} alt="" width={40} height={40} style={{ flexShrink: 0 }} />
            <div className={styles.walletInfo}>
              <UITypography component="p" variant="H2" style={{ flexShrink: 0 }}>
                {`${t('settings.promo_ref_link')}:`}
              </UITypography>

              <UITypography>{user.promo_link_for_referrals}</UITypography>
            </div>
          </div>

          <TickCircle />
        </div>
      ) : (
        <UIButton fullwidth onClick={openAddLinkModal}>
          {t('settings.add_promo_ref_link_modal.button')}
        </UIButton>
      )}

      <AddPromoRefLinkModal
        visible={visibleAddLinkModal}
        onClose={() => setVisibleAddLinkModal(false)}
      />
    </div>
  );
};
