import { createWithEqualityFn } from 'zustand/traditional';
import { deepShallow } from 'shared/utils';
import { UiButtonProps } from 'shared/ui/components/button/button';
import React from 'react';

type Button = UiButtonProps & { label?: string; onClick: () => void };

type State = {
  visible: boolean;
  title: string | React.ReactNode;
  text: string;
  buttons?: Button[];
};

type Actions = {
  open: (state: Omit<State, 'visible'>) => void;
  close: () => void;
};

type Store = State & Actions;

const initialState: State = {
  visible: false,
  title: '',
  text: '',
  buttons: [],
};

export const useInfoModalStore = createWithEqualityFn<Store>(
  (set) => ({
    ...initialState,
    open: (state) =>
      set({
        visible: true,
        ...state,
      }),
    close: () => set(initialState),
  }),
  deepShallow,
);
