import { UIButton, UIModal, UIModalProps, UITypography } from 'shared/ui';
import { TOKEN_SHORTCUT } from 'shared/constants';
import styles from './style.module.scss';
import { formatLargeNumber } from 'shared/utils';

export interface TokenData {
  name: string;
  address: string;
  symbol: string;
  decimals: number;
  chainId: number;
  logoURI: string;
}

export const USDT_TOKEN: TokenData = {
  name: 'Tether USD',
  address: '0x55d398326f99059ff775485246999027b3197955',
  symbol: 'USDT',
  decimals: 18,
  chainId: 56,
  logoURI:
    'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xdAC17F958D2ee523a2206206994597C13D831ec7/logo.png',
};

export const MAIN_GAME_TOKEN: TokenData = {
  name: TOKEN_SHORTCUT,
  address: process.env.REACT_APP_TOKEN_ADDRESS as string,
  symbol: TOKEN_SHORTCUT,
  decimals: 18,
  chainId: 56,
  logoURI: '/bobe-coin.webp',
};

export const AmountWithTokenData = ({
  amount,
  token,
  decimals = 8,
}: {
  amount: string;
  token: TokenData;
  decimals?: number;
}) => {
  return (
    <div className={styles.amountWithToken}>
      <UITypography variant="H2">{formatLargeNumber(amount, decimals)}</UITypography>
      <img src={token.logoURI} alt="" className={styles.tokenIcon} />
      <UITypography variant="H2">{token.symbol}</UITypography>
    </div>
  );
};
