import classNames from 'classnames';
import { ChangeEvent, forwardRef, InputHTMLAttributes } from 'react';
import styles from './style.module.scss';

type CommonInputProps = InputHTMLAttributes<HTMLInputElement>;
type UINumberInputVariant = 'secondary';

export type UINumberInputProps = Omit<CommonInputProps, 'size' | 'onChange'> & {
  formatValue?: boolean;
  onChange?: (value: string) => void;
  variant?: UINumberInputVariant;
  decimals?: number;
};

export const UINumberInput = forwardRef<HTMLInputElement, UINumberInputProps>(
  ({ onChange, formatValue = true, className, variant, value, decimals = 4, ...other }, ref) => {
    const mergedClassName = classNames(styles.input, variant ? styles[variant] : '', className);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      const _value = e.target.value.replaceAll(',', '.').replaceAll(' ', '');

      if (_value === '') {
        onChange?.('');
        return;
      }

      const regex = new RegExp(`^(?!.*\\.\\.)\\d+(\\.\\d{0,${decimals}})?$`);

      if (regex.test(_value)) {
        if (_value.endsWith('.')) {
          onChange?.(_value.replace(/\B(?=(\d{3})+(?!\d))/g, ' '));
          return;
        }

        const splitted = _value.split('.');
        const formattedInteger = splitted[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        const formattedValue = splitted[1]
          ? `${formattedInteger}.${splitted[1]}`
          : formattedInteger;

        onChange?.(formattedValue);
      }
    };

    return (
      <input
        ref={ref}
        type="text"
        inputMode="decimal"
        pattern="[0-9]*"
        onChange={handleChange}
        className={mergedClassName}
        value={value}
        {...other}
      />
    );
  },
);
