import styles from './style.module.scss';
import { Swipe } from 'shared/ui/animations';

import { UITypography } from 'shared/ui';
import variables from 'shared/ui/theme/variables.scss';

import { useEffect, useRef, useMemo, memo, useState } from 'react';
import { useGameStore, useGlobalStore } from 'shared/store';
import { getValuesForTimer } from 'shared/utils';
import classNames from 'classnames';
import { BOOSTERS_ICONS } from 'shared/constants/boosters';
import { BoosterType } from 'shared/types';
type useGameTimerProps = {
  startValue: number;
};

export const useGameTimer = ({ startValue }: useGameTimerProps) => {
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const appVisible = useGlobalStore((state) => state.appVisible);
  const { elapsedTime, sessionConfig, gameFinished, updateGameTime } = useGameStore((state) => ({
    elapsedTime: state.elapsedTime,
    sessionConfig: state.sessionConfig,
    gameFinished: state.gameFinished,
    updateGameTime: state.updateGameTime,
  }));

  useEffect(() => {
    if (!sessionConfig || !appVisible || gameFinished) {
      if (intervalRef.current) clearInterval(intervalRef.current);
      return;
    }

    updateGameTime();
    intervalRef.current = setInterval(updateGameTime, 1000);

    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, [sessionConfig, appVisible, gameFinished, updateGameTime]);

  // Calculate remaining time directly from store's elapsedTime
  const remainingSeconds = sessionConfig
    ? Math.max(0, sessionConfig.session_duration - Math.floor(elapsedTime))
    : startValue;

  return { gameTime: getValuesForTimer(remainingSeconds), seconds: remainingSeconds };
};

const GameTimer = memo(({ startValue }: { startValue: number }) => {
  const { gameTime, seconds } = useGameTimer({ startValue });
  const currentPhase = useGameStore((state) => state.currentPhase);
  const danger = currentPhase === 3;

  const timerStyles = useMemo(
    () => ({
      border: `2px solid ${danger ? variables.colorRose : 'transparent'}`,
      backgroundColor: danger ? variables.colorRose20 : variables.colorWhite20,
      color: danger ? variables.colorRose : variables.colorWhite,
    }),
    [danger],
  );

  return (
    <div className={styles.timer} style={timerStyles}>
      <UITypography color={'inherit'} variant="Button1">
        {`${gameTime.minutes}:${gameTime.secondsLeft}`}
      </UITypography>
    </div>
  );
});

const CoinsCounter = memo(() => {
  const currCoins = useGameStore((state) => state.currCoins);

  return (
    <div className={styles.counter}>
      <img
        style={{ pointerEvents: 'none' }}
        src={'/donuts/don-coin.webp'}
        alt=""
        width={40}
        height={40}
      />
      <UITypography variant="Award">{currCoins}</UITypography>
    </div>
  );
});

export const GamePanel = () => {
  const sessionConfig = useGameStore((state) => state.sessionConfig);
  const SESSION_BOOSTERS = [BoosterType.DON_X, BoosterType.DURATION_X, BoosterType.INSECTS_X];
  const activeBoosters =
    sessionConfig?.active_boosters
      /* Показываем только бустеры влияющие на игровую сессию */
      .filter((booster) => SESSION_BOOSTERS.includes(booster.type))
      .map((booster) => BOOSTERS_ICONS[booster.type]) || [];

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <GameTimer startValue={sessionConfig?.session_duration ?? 0} />

        {activeBoosters.length ? (
          <div className={styles.boosters}>
            {activeBoosters.map((image) => (
              <img src={image} key={image} alt="" />
            ))}
          </div>
        ) : null}

        <CoinsCounter />
      </div>
    </div>
  );
};
