import { UIButton, UITypography } from 'shared/ui';
import { Sheet } from 'react-modal-sheet';
import styles from './style.module.scss';
import { ReactComponent as CloseIcon } from 'assets/icons/close-icon.svg';
import { forwardRef } from 'react';
import { useInfoModalStore } from 'shared/store/info-modal';

export const UIInfoModal = forwardRef(() => {
  const {
    text,
    title,
    visible,
    close: onClose,
    buttons = [],
  } = useInfoModalStore((state) => state);

  return (
    <Sheet isOpen={visible} onClose={onClose} className={styles.root}>
      <Sheet.Container className={styles.container}>
        <Sheet.Scroller>
          <Sheet.Header className={styles.header}>
            <button className={styles.close} onClick={onClose}>
              <CloseIcon />
            </button>
          </Sheet.Header>

          <Sheet.Content className={styles.content}>
            <UITypography color="black" className={styles.title} variant="H1" component="h2">
              {title}
            </UITypography>

            <UITypography color="black" variant="Body" fontWeight="bold" component="p">
              {text}
            </UITypography>

            {buttons.map((button, index) => (
              <UIButton {...button} key={index}>
                {button.label || button.children}
              </UIButton>
            ))}
          </Sheet.Content>
        </Sheet.Scroller>
      </Sheet.Container>
      <Sheet.Backdrop onTap={onClose} />
    </Sheet>
  );
});
