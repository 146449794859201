import styles from './style.module.scss';
import { useState, useRef, useCallback, FocusEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { UITypography, UIButton, UINumberInput } from 'shared/ui';
import { useAlert } from 'shared/ui/components/alert/alert';

type ContentProps = {
  onClose: () => void;
  onFocus: FocusEventHandler;
  onBlur: FocusEventHandler;
};

export const Content = ({ onClose, onFocus, onBlur }: ContentProps) => {
  const { t } = useTranslation();
  const { showAlert } = useAlert();
  const [value, setValue] = useState('');
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleChange = (_value: string) => {
    setValue(_value);
  };

  const getMoreTokens = useCallback(() => {
    const _value = +value.replaceAll(' ', '');

    if (_value < 1 || isNaN(_value) || !_value) {
      showAlert(t('alerts.incorrect_value'));
      return;
    }
    // TODO: redirect to link and pass query param with value
    onClose();
    setValue('');
  }, [value, onClose, t]);

  return (
    <div className={styles.content}>
      <UITypography variant="H1">{t('progress.input_tokens_count')}</UITypography>

      <UINumberInput
        ref={inputRef}
        variant="secondary"
        value={value}
        onChange={handleChange}
        className={styles.tokensInput}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      <UIButton onClick={getMoreTokens} fullwidth>
        {t('progress.get_tokens')}
      </UIButton>
    </div>
  );
};
