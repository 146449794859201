import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import { UIButton, UIModal, UITypography } from 'shared/ui';
import BronzeCoinIcon from 'assets/images/bronze-coin.png';

export const BuyBoosterModal = ({
  image,
  description,
  amount,
  visible,
  loading,
  onClose,
  onClick,
}: {
  image: string;
  description: string;
  amount: string;
  visible: boolean;
  loading: boolean;
  onClose: () => void;
  onClick: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <UIModal visible={visible} onClose={onClose} detent="content-height">
      <div className={styles.wrapper}>
        <img className={styles.img} src={image} />
        <UITypography className={styles.title} variant="H1">
          {description}
        </UITypography>

        <UIButton
          fullwidth
          className={styles.btn}
          onClick={onClick}
          loading={loading}
          disabled={loading}
        >
          {t('common.buy_for')} {Number(amount)} <img src={BronzeCoinIcon} width={24} height={24} />
        </UIButton>
      </div>
    </UIModal>
  );
};
