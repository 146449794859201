import styles from './style.module.scss';
import classNames from 'classnames';
import { CSSProperties, ReactNode, MouseEventHandler, forwardRef } from 'react';

export type TypographyVariant = 'H1' | 'H2' | 'Body' | 'Menu' | 'Button1' | 'Button2' | 'Award';

export type ComponentVariant = 'span' | 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
export type Color = 'black' | 'white' | 'yellow' | 'inherit';

type TypographyProps = {
  variant?: TypographyVariant;
  children: ReactNode | string | number;
  component?: ComponentVariant;
  className?: string;
  style?: CSSProperties;
  color?: Color;
  preline?: boolean;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  fontWeight?: 'regular' | 'bold' | 'extraBold';
  id?: string;
  onClick?: MouseEventHandler<HTMLElement>;
  copied?: boolean;
};

export const UITypography = forwardRef<any, TypographyProps>(
  (
    {
      variant = 'Body',
      children,
      component = 'span',
      className,
      style,
      color = 'white',
      preline = true,
      iconLeft,
      iconRight,
      fontWeight,
      id,
      onClick,
      copied,
    },
    ref,
  ) => {
    const mergedClassName = classNames(
      styles[variant],
      styles[color],
      preline ? styles.preline : '',
      iconLeft || iconRight ? styles.withIcon : '',
      fontWeight ? styles[fontWeight] : '',
      copied ? styles.copied : '',
      className,
    );

    const Component = component;

    return (
      <Component className={mergedClassName} style={style} id={id} ref={ref} onClick={onClick}>
        {iconLeft && <div className={styles.icon}>{iconLeft}</div>}
        {children}
        {iconRight && <div className={styles.icon}>{iconRight}</div>}
      </Component>
    );
  },
);
