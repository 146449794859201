import styles from './style.module.scss';
import { UIButton, UITypography } from 'shared/ui';
import { useWebApp } from '@vkruglikov/react-telegram-web-app';
import { useGlobalStore } from 'shared/store';
import { useTranslation } from 'react-i18next';
import { formatLargeNumber } from 'shared/utils';
import classNames from 'classnames';
import { COMMON_PRELOAD_ASSETS } from 'shared/constants';

type UserWidgetProps = {
  username?: string;
  avatar?: string;
  isReferral?: boolean;
  isApproved?: boolean;
  percengage?: number;
  showCurrency?: boolean;
  className?: string;
  tgUserId?: number;
  tgUsername?: string;
  onClick?: () => void;
  asLink?: boolean;
};

export const UserWidget = ({
  username,
  // avatar,
  isReferral,
  isApproved,
  percengage,
  className,
  // tgUserId,
  tgUsername,
  onClick,
  // asLink = false,
}: UserWidgetProps) => {
  const user = useGlobalStore((state) => state.user);
  // const [initDataUnsafe] = useInitData();
  // const tgUser = initDataUnsafe?.user;
  const _username = username ? username : user.username || user.tg_user_id;
  const webApp = useWebApp();

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else if (tgUsername) {
      // console.log('opening: ' + `tg://user?id=${tgUserId}`);
      console.log('opening: ' + `https://t.me/${tgUsername}`);
      webApp.openTelegramLink(`https://t.me/${tgUsername}`);
      // webApp.openTelegramLink(`tg://user?id=${tgUserId}`);
    }
  };

  return (
    <div className={classNames(styles.root, className)} onClick={handleClick}>
      <div className={styles.container}>
        {!isReferral && (
          <div className={styles.avatar}>
            <img
              src={COMMON_PRELOAD_ASSETS.userAvatar}
              alt="avatar"
              className={styles.avatarImage}
            />
          </div>
        )}

        <UITypography fontWeight="bold" className={styles.username}>
          {_username}
        </UITypography>
      </div>

      {isReferral && <TokensCount isApproved={isApproved} percengage={percengage} />}
    </div>
  );
};

const TokensCount = ({ isApproved, percengage }: UserWidgetProps) => {
  const { t } = useTranslation();
  return isApproved ? (
    <UITypography
      fontWeight="bold"
      variant="Button2"
    >{`+${formatLargeNumber(percengage || 0, 1)} DON`}</UITypography>
  ) : (
    <UIButton disabled size="small2" fontVariant="Button2">
      {t('common.pending')}
    </UIButton>
  );
};
