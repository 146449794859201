import styles from './style.module.scss';
import { useGlobalStore } from 'shared/store';
import { BakeryBlocked } from './bakery-blocked/bakery-blocked';
import { Conversation } from './conversation/conversation';
import {
  MainTokenBalance,
  BakeryGlobalStats,
  DailyBenefitHistory,
  BakeryUserStats,
  UserWalletHistory,
} from './main-token-balance/main-token-balance';
import {
  SendToBakery,
  SendToBakeryHistory,
  BakeButton,
  UnbakeButton,
} from './send-to-bakery/send-to-bakery';
import { Swipe } from 'shared/ui/animations';

import { createAppKit } from '@reown/appkit/react';
import { EthersAdapter } from '@reown/appkit-adapter-ethers';
import { bsc, bscTestnet } from '@reown/appkit/networks';

// TODO: add every website to the cloud
const projectId = '42dcdd7c0931eec66caeb2e1724f16e9';

createAppKit({
  adapters: [new EthersAdapter()],
  networks: [bsc],
  defaultNetwork: bsc,
  // networks: [bscTestnet],
  // defaultNetwork: bscTestnet, // TODO: temporary
  metadata: {
    name: 'BoBe Bakery',
    description: 'P2E Game: Catch Donuts and Bake them to get $USDT',
    url: window.location.origin,
    icons: [`${window.location.origin}/new-year/users_avatar.webp`], // TODO: change pic?
  },
  projectId,
  features: {
    email: false, // default to true
    socials: [], // ['google', 'x', 'github', 'discord', 'apple', 'facebook', 'farcaster'],
    emailShowWallets: false, // default to true
    swaps: false, // default to true
    onramp: false, // default to true
  },
  themeVariables: {
    '--w3m-color-mix': '#373d8c',
    '--w3m-color-mix-strength': 40,
    '--w3m-z-index': 99999999,
  },
  // tokens: {
  //   'eip155:97': {
  //     address: '0x2b4f7e43B6869f763Be8446154Cd359B30882c46',
  //     // image: 'token_image_url' //optional
  //   },
  // },
});

export const BakeryModule = () => {
  const user = useGlobalStore((state) => state.user);

  if (!user.has_bakery_access) {
    return (
      <div className={styles.root}>
        <Swipe from="top" direction="vertical" initialTransparent={false}>
          <BakeryBlocked />
        </Swipe>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <Swipe from="top" direction="vertical" initialTransparent={false}>
        <Conversation />
      </Swipe>
      <Swipe from="left" direction="horizontal">
        <MainTokenBalance />

        {/* <SendToBakery />
        <SendToBakeryHistory />

        <BakeryGlobalStats />
        <DailyBenefitHistory />

        <BakeryUserStats /> */}

        {/* <BakeButton />
        <UnbakeButton /> */}

        {/* <UserWalletHistory /> */}
      </Swipe>

      {/* <Swipe from="right" direction="horizontal">
      </Swipe> */}
    </div>
  );
};
