import { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import styles from './style.module.scss';
import { alertService } from 'shared/ui/components/alert/service';

type AlertContextType = {
  showAlert: (message: string) => void;
};

const AlertContext = createContext<AlertContextType>({ showAlert: () => {} });

export const useAlert = () => useContext(AlertContext);

type AlertProviderProps = {
  children: ReactNode;
};

export const AlertProvider = ({ children }: AlertProviderProps) => {
  const [message, setMessage] = useState('');
  const [isVisible, setIsVisible] = useState(false);

  const showAlert = (msg: string) => {
    setMessage(msg);
    setIsVisible(true);
  };

  const handleClose = () => {
    setIsVisible(false);
    setMessage('');
  };

  useEffect(() => {
    alertService.init(showAlert);
  }, []);

  return (
    <AlertContext.Provider value={{ showAlert }}>
      {children}
      {isVisible && (
        <div className={styles.overlay}>
          <div className={styles.alert}>
            <p className={styles.message}>{message}</p>
            <button className={styles.button} onClick={handleClose}>
              OK
            </button>
          </div>
        </div>
      )}
    </AlertContext.Provider>
  );
};
