import classNames from 'classnames';
import styles from './style.module.scss';
import { ReactComponent as VideoPlayIcon } from 'assets/icons/video-play.svg';
import { useAdsgram } from 'shared/hooks';
import { ShowPromiseResult } from 'shared/types/adsgram';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UITypography } from 'shared/ui';
import { ROUTES } from 'shared/constants';
import { useGameStore, useGlobalStore } from 'shared/store';
import { updateBonusLimit, addBonusSkipWaiting } from 'shared/api';
import { TurboBoostModal } from 'components/modals/turbo-boost-modal/turbo-boost-modal';
import { AdsGramErrorType } from 'shared/types';
import { useAlert } from 'shared/ui/components/alert/alert';

type RewardType = 'daily_limit_increase' | 'start_session_instantly';

type ShowAdsButtonProps = {
  style?: React.CSSProperties;
  className?: string;
  rewardType: RewardType;
  textRight?: string | React.ReactNode;
  blockId: string;
};

export const ShowAdsButton = ({
  style,
  className,
  rewardType,
  textRight,
  blockId,
}: ShowAdsButtonProps) => {
  const nav = useNavigate();
  const { showAlert } = useAlert();

  const setGameReady = useGameStore((state) => state.setGameReady);
  const updateUser = useGlobalStore((state) => state.updateUser);
  const [visibleTurboBoostModal, setVisibleTurboBoostModal] = useState(false);

  const rewardHandlers = {
    start_session_instantly: async () => {
      const newUser = await addBonusSkipWaiting();
      // updateUser({ // idk there seems to be no limit right now at all
      //   daily_bonus_limit: newUser.daily_bonus_limit,
      //   current_count_ads: newUser.current_count_ads,
      // });
      setGameReady(true);
      nav(ROUTES.SESSION, { replace: true });
    },

    daily_limit_increase: async () => {
      const newUser = await updateBonusLimit();
      updateUser({
        next_available_start: newUser.next_available_start,
        max_daily_limit: newUser.max_daily_limit,
        daily_bonus_limit: newUser.daily_bonus_limit,
        current_count_ads: newUser.current_count_ads,
      });
    },
  };

  const onReward = useCallback(async (result: ShowPromiseResult) => {
    try {
      if (!result.done) {
        showAlert("Ad hasn't done");
        return;
      }

      rewardHandlers[rewardType]();
    } catch (error: any) {
      console.log('error.description');
      console.log(error.description);
    }
  }, []);

  const handleTurboBoostModalComplete = () => {
    setVisibleTurboBoostModal(false);
    rewardHandlers[rewardType]();
  };

  const onError = useCallback((payload: { type: AdsGramErrorType }) => {
    const { type } = payload;

    console.log('ADS ERROR: ', payload);
    // Сначала пробуем показать Sonar
    window.Sonar?.show({
      adUnit: 'rewards',
      onReward: () => {
        rewardHandlers[rewardType]();
      },
      onError: () => {
        // Если и Sonar не сработал - показываем модалку
        setVisibleTurboBoostModal(true);
      },
    });
  }, []);

  const showAds = useAdsgram({ onReward, onError, blockId });

  return (
    <>
      <div className={classNames(styles.root, className)} style={style} onClick={showAds}>
        <VideoPlayIcon />
        {typeof textRight !== 'string' ? (
          textRight
        ) : (
          <UITypography color="white" fontWeight="bold">
            {textRight}
          </UITypography>
        )}
      </div>

      <TurboBoostModal
        visible={visibleTurboBoostModal}
        onComplete={() => handleTurboBoostModalComplete()}
        onClose={() => setVisibleTurboBoostModal(false)}
        variant={rewardType}
      />
    </>
  );
};
