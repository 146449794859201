import { ENDPOINTS } from 'shared/constants';
import { httpClientAuthorized } from '../httpClient';
import { GetRefsResponse, LeagueInfo } from 'shared/types';

type GetRefsParams = {
  limit: number;
  offset: number;
  level: number;
  get_referrer_promo_link?: boolean;
};

export const getRefs = async ({
  level,
  offset,
  limit,
  get_referrer_promo_link = false,
}: GetRefsParams) => {
  return await httpClientAuthorized.get<GetRefsResponse>(
    ENDPOINTS.GET_REFERALS(limit, offset, level, get_referrer_promo_link),
  );
};

export const acceptInvite = async (referrer_id: number) => {
  return await httpClientAuthorized.post<void>(ENDPOINTS.ACCEPT_INVITE, {
    referrer_id,
  });
};

export const getLeagueInfo = async () => {
  return await httpClientAuthorized.get<LeagueInfo>(ENDPOINTS.LEAGUE_INFO);
};

export const updateLeagueInfo = async () => {
  return await httpClientAuthorized.post<LeagueInfo>(ENDPOINTS.LEAGUE_INFO);
};
