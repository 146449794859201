import { useCallback, useEffect, useRef, useState } from 'react';
import { FLY_SIZE, MAX_FREEZE_COOLDOWN } from 'shared/constants';
import { useGameStore, useGlobalStore } from 'shared/store';
import { FlyEntity } from 'shared/types';
import { v4 } from 'uuid';
import { clearEntityQueue } from '../utils';

export const useFlies = () => {
  const [flies, setFlies] = useState<FlyEntity[]>([]);

  const gameFinished = useGameStore((state) => state.gameFinished);
  const settings = useGameStore((state) => state.settings);
  const setFrozen = useGameStore((state) => state.setFrozen);
  const increaseCatchedBees = useGameStore((state) => state.increaseCatchedBees);
  const actualSessionDuration = useGameStore((state) => state.actualSessionDuration);
  const sessionStartTime = useGameStore((state) => state.sessionStartTime);
  const sessionConfig = useGameStore((state) => state.sessionConfig);

  const sessionBeeInterval = sessionConfig?.bee_spawn_interval ?? 999999;

  const [freezeCooldown, setFreezeCooldown] = useState(settings.cooldown_duration * 1000);
  const flyDeleteQueue = useRef<string[]>([]);

  const onFlyAnimationComplete = useCallback((id: string) => {
    flyDeleteQueue.current.push(id);
  }, []);

  const onFlyClick = useCallback(() => {
    setFrozen(true);
    setFreezeCooldown((prev) => (prev < MAX_FREEZE_COOLDOWN ? prev + 1000 : MAX_FREEZE_COOLDOWN));
    increaseCatchedBees(1);
  }, [setFrozen, increaseCatchedBees]);

  const createFly = useCallback((screenWidth: number, screenHeight: number, startDelay: number) => {
    const id = `fly-id${v4()}`;

    const startX = Math.random() < 0.5 ? -FLY_SIZE : screenWidth + FLY_SIZE;
    const startY = Math.random() * (screenHeight - FLY_SIZE);
    const endX = startX > 0 ? -FLY_SIZE : screenWidth + FLY_SIZE;
    const endY = Math.random() * (screenHeight - FLY_SIZE);
    const deltaX = endX - startX;
    const deltaY = endY - startY;
    const angleInRadians = Math.atan2(deltaY, deltaX);
    const rotation = angleInRadians * (180 / Math.PI) + 90;

    return { id, startX, startY, endX, endY, rotation, startDelay };
  }, []);

  // All logic is here
  useEffect(() => {
    if (!sessionStartTime || !actualSessionDuration || gameFinished) return;

    const fliesToAddCount = Math.round(2000 / (sessionBeeInterval * 1000));
    const spawnInterval = fliesToAddCount * sessionBeeInterval * 1000;
    const timeForNewBatch = (fliesToAddCount - 1) * sessionBeeInterval + settings.fall_bee_speed;
    const maxFliesOnScreen = fliesToAddCount * 3;

    let isActive = true;
    let lastSpawnTime = 0;
    let initialSpawnDone = false;
    // eslint-disable-next-line prefer-const
    let checkIntervalId!: NodeJS.Timeout;

    // This function handles all spawn logic and timing checks
    const checkAndSpawn = () => {
      if (!isActive || gameFinished) return;

      const now = Date.now();
      const currentTime = (now - sessionStartTime!) / 1000;
      const remainingTime = actualSessionDuration! - currentTime;

      // Check if we need to stop spawning
      if (remainingTime <= timeForNewBatch) {
        isActive = false;
        clearInterval(checkIntervalId);
        return;
      }

      // Calculate how much time has passed since last spawn
      const timeSinceLastSpawn = now - lastSpawnTime;

      // Spawn if:
      // 1. We haven't done initial spawn yet, OR
      // 2. Enough time has passed AND we have enough remaining game time
      if (!initialSpawnDone || timeSinceLastSpawn >= spawnInterval) {
        lastSpawnTime = now;
        initialSpawnDone = true;

        setFlies((prev) => {
          const windowWidth = window.innerWidth;
          const windowHeight = window.innerHeight;
          const fliesQueue = Array.from({ length: fliesToAddCount }, (_, index) =>
            createFly(windowWidth, windowHeight, index * sessionBeeInterval),
          );

          if (flyDeleteQueue.current.length > 0) {
            const filteredPrev = clearEntityQueue(prev, flyDeleteQueue);
            return [...filteredPrev, ...fliesQueue].slice(-maxFliesOnScreen);
          }

          return [...prev, ...fliesQueue].slice(-maxFliesOnScreen);
        });
      }
    };

    checkIntervalId = setInterval(checkAndSpawn, 500);

    // Run initial spawn check immediately
    checkAndSpawn();

    // Clean up all timers when the effect is cleaned up
    return () => {
      isActive = false;
      clearInterval(checkIntervalId);
    };
  }, [gameFinished, createFly, settings, sessionStartTime, actualSessionDuration]);

  return {
    flies,
    onFlyClick,
    freezeCooldown,
    onFlyAnimationComplete,
  };
};
