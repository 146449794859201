import styles from './style.module.scss';
import { memo, useRef, useState, useMemo, CSSProperties } from 'react';
import { COMMON_PRELOAD_ASSETS, FLY_SIZE } from 'shared/constants';
import { useGameStore, useGlobalStore } from 'shared/store';
import { FlyEntity } from 'shared/types';
import { useHapticFeedback } from '@vkruglikov/react-telegram-web-app';

type FlyProps = FlyEntity & {
  onClick?: (id: string) => void;
  onAnimationComplete: (id: string) => void;
};

export const Fly = memo(
  ({
    startX,
    startY,
    onClick,
    id,
    endX,
    endY,
    rotation,
    onAnimationComplete,
    startDelay,
  }: FlyProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const [isClicked, setIsClicked] = useState(false);

    // const animationDuration = useGameStore().settings.fall_bee_speed;
    // only this way every bee won't bee rerendered on every timer tick
    const animationDuration = useGameStore(
      (state) => state.settings.fall_bee_speed,
      (prev, next) => prev === next,
    );
    const user = useGlobalStore((state) => state.user);

    const [_, notificationOccurred] = useHapticFeedback();

    const handleClick = () => {
      // audioManager.play('bee'); // TODO: ...

      if (isClicked) return;
      // console.log('fly clicked');

      if (user.is_vibro_enabled) {
        for (let i = 0; i < 20; i++) {
          setTimeout(() => {
            notificationOccurred('error');
            // notificationOccurred(Math.random() < 0.5 ? 'error' : 'warning');
          }, i * 100);
        }
      }

      onClick?.(id);
      onAnimationComplete(id);
      setIsClicked(true);
      if (!ref.current) return;
      ref.current.style.pointerEvents = 'none';
      ref.current.style.opacity = '0';
    };

    const flyStyle = useMemo(
      (): CSSProperties => ({
        position: 'absolute',
        ['--start-x' as string]: `${startX}px`,
        ['--start-y' as string]: `${startY}px`,
        ['--end-x' as string]: `${endX}px`,
        ['--end-y' as string]: `${endY}px`,
        ['--animation-duration' as string]: `${animationDuration}s`,
        ['--start-delay' as string]: `${startDelay}s`,
        animationDuration: `${animationDuration}s`,
        animationDelay: `${startDelay}s`,
      }),
      [startDelay, animationDuration, startX, startY, endX, endY],
    );

    return (
      <div
        ref={ref}
        id={id}
        className={styles.root}
        style={flyStyle}
        onTouchStart={handleClick}
        onMouseDown={handleClick}
      >
        <img
          src={COMMON_PRELOAD_ASSETS.fly}
          alt=""
          className={styles['fly-image']}
          style={{
            width: `${FLY_SIZE}px`,
            height: `${FLY_SIZE}px`,
            transform: `translate3d(0,0,0) rotate(${rotation}deg)`,
          }}
        />
      </div>
    );
  },
  (prev, next) => prev.id === next.id,
);
