import { useTranslation } from 'react-i18next';
import { useInfoModal } from 'shared/hooks/use-info-modal';
import { useGlobalStore } from 'shared/store';
import EmojiCry from 'assets/images/emoji-cry.webp';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'shared/constants';

export const useCheckBobeBalance = () => {
  const navigate = useNavigate();
  const infoModal = useInfoModal();
  const { t } = useTranslation();
  const tokens = useGlobalStore((state) => state.user.tokens);

  const checkBobeBalance = (price: number | string) => {
    return Number(tokens) >= Number(price);
  };

  const openNoBobeTokensModal = () => {
    infoModal.open({
      title: (
        <>
          {`${t('common.oops')}!...`}
          <img src={EmojiCry} width={20} height={20} alt="" />
        </>
      ),
      text: t('info_modal.no_bobe_on_balance'),
      buttons: [
        {
          label: t('info_modal.back_to_bakery'),
          onClick: () => {
            navigate(ROUTES.BAKERY);
            infoModal.close();
          },
        },
      ],
    });
  };

  return {
    checkBobeBalance,
    openNoBobeTokensModal,
  };
};
