import { AchievementType } from 'shared/types';

export const ENDPOINTS = {
  CREATE_USER: '/v1/users/',
  LOGIN: '/v1/users/login/',
  UPDATE_USER: '/v1/users/',
  UPDATE_USER_WALLET: '/v1/users/update-user-wallet/',
  UPDATE_USER_PROMO_REF_LINK: '/v1/users/update-user-promo-link/',
  GET_USER: '/v1/users/',
  COMPLETE_TASK: (taskId: string) => `/v1/tasks/${taskId}/`,
  GET_REFERALS: (limit: number, offset: number, level: number, get_referrer_promo_link: boolean) =>
    `/v1/referrals/?limit=${limit}&offset=${offset}&level=${level}&get_referrer_promo_link=${get_referrer_promo_link}`,
  ACCEPT_INVITE: '/v1/referrals/',
  REFS_INFO: '/v1/referrals/info/',
  START_SESSION: '/v1/game/sessions/',
  FINISH_SESSION: (session_id: string) => `/v1/game/sessions/${session_id}/`,
  GET_SETTINGS: '/v1/game/settings/',
  GET_TASKS: '/v1/tasks/',
  GET_ACHIEVEMENTS: (type: AchievementType) => `/v1/achievements/?kind=${type}`,
  FAQ: '/v1/faqs/',
  LEAGUE_INFO: '/v1/referrals/leagues/',
  UNLOCK_BAKERY: '/v1/users/bakery-access/',
  BAKERY_CONVERT: '/v1/users/bakery-convert/',
  BAKERY_WITHDRAW: '/v1/users/bakery-withdraw/',
  BAKERY_STAKE: '/v1/users/bakery-stake/',
  BAKERY_HISTORY: (limit: number, offset: number) =>
    `/v1/users/bakery-history/?limit=${limit}&offset=${offset}`,
  ANNOUNCE_HISTORY: (limit: number, offset: number) =>
    `/v1/users/announce-history/?skip=${offset}&limit=${limit}`,
  GET_USER_HISTORY: (wallet: string, limit: number, offset: number, type?: string) =>
    `/v1/users/history/${wallet}/?limit=${limit}&skip=${offset}${type && type != '' ? `&type=${type}` : ''}`,
  CURRENT_TIME: '/v1/game/time/',
  OPEN_API: '/v1/openapi.json',
  BONUS_LIMIT: 'v1/users/bonus-limit/',
  SKIP_WAITING: 'v1/users/skip-waiting/',
  BOT: 'v1/users/bot/',
  TOKEN: '/v1/users/token/',

  // Boosters
  SKIP_WAITING_ONCE: '/v1/shop/skip-waiting-once',
  BUY_BONUS_LIMIT_ONCE: '/v1/shop/buy-bonus-limit-once',

  // Real Boosters
  BUY_BOOSTER: '/v1/shop/buy-booster/',
  ACTIVATE_BOOSTER: '/v1/shop/activate-booster/',
};
