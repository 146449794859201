import styles from './style.module.scss';
import { UITypography } from 'shared/ui';
import { useTranslation } from 'react-i18next';

import { BoosterType, BoosterInfo } from 'shared/types';
import { buyBooster, activateBooster } from 'shared/api';
import { useGameStore, useGlobalStore } from 'shared/store';
import classNames from 'classnames';
import { useState } from 'react';
import { Nullable } from 'shared/types/utils';
import { BuyBoosterModal } from 'components/modals/buy-booster-modal/buy-booster-modal';
import { BOOSTERS_ICONS } from 'shared/constants/boosters';
import { DateTime } from 'luxon';
import { useCheckBobeBalance } from 'shared/hooks/use-check-bobe-balance';
import { useAlert } from 'shared/ui/components/alert/alert';

const BOOSTERS_LIST = [
  BoosterType.INSECTS_X,
  BoosterType.DON_X,
  BoosterType.DURATION_X,
  BoosterType.DAILY_BONUS_LIMIT_PLUS,
];

const BoosterCard = ({
  disabled,
  type,
  boosterInfo,
  onClick,
  onClickBuy,
}: {
  disabled: boolean;
  type: BoosterType;
  boosterInfo?: BoosterInfo;
  onClick: () => void;
  onClickBuy: () => void;
}) => {
  const { t } = useTranslation();

  const handleClickBuy = (event: any) => {
    console.log('handleClickBuy');
    onClickBuy();
    event.stopPropagation();
  };

  const icon = BOOSTERS_ICONS[type];
  const title = t(`boosters.${type}.title`) || '';

  const timer =
    boosterInfo && boosterInfo.seconds_left
      ? DateTime.fromSeconds(boosterInfo.seconds_left).toFormat('HH:mm:ss')
      : '';

  const boosterClassList = classNames([
    styles.booster,
    {
      [styles._active]: boosterInfo?.has_active && !disabled,
      // [styles._disabled]: disabled,
    },
  ]);

  return (
    <button onClick={onClick} className={boosterClassList}>
      {timer && (
        <div className={styles.boosterTimerContainer}>
          <div className={styles.boosterTimer}>
            <img className={styles.boosterTimerImage} src="/boosters/buttons/timer.webp" />
            <UITypography className={styles.boosterTimerText} variant="Menu" fontWeight="regular">
              {timer}
            </UITypography>
          </div>
        </div>
      )}

      <img src={icon} alt={title} className={styles.boosterImage} />
      <UITypography className={styles.boosterTitle} variant="Menu" fontWeight="regular">
        {title}
      </UITypography>

      <div className={styles.buyButtonContainer}>
        <button onClick={handleClickBuy} className={styles.buyButton}>
          <img className={styles.buyButtonImage} src="/boosters/buttons/boost.webp" />
          {/* {boosterInfo?.count ? (
            <UITypography className={styles.buyButtonText}>{boosterInfo.count}</UITypography>
          ) : (
            null
          )} */}
          <UITypography className={styles.buyButtonText}>{boosterInfo?.count ?? 0}</UITypography>
        </button>
      </div>
    </button>
  );
};

export const Boosters = () => {
  const { checkBobeBalance, openNoBobeTokensModal } = useCheckBobeBalance();
  const { t } = useTranslation();
  const { showAlert } = useAlert();
  const boosters = useGlobalStore((state) => state.user.boosters);
  const [loading, setLoading] = useState(false);
  const [selectedBoosterType, setSelectedBoosterType] = useState<Nullable<BoosterType>>(null);
  const settings = useGameStore((state) => state.settings);
  const updateUser = useGlobalStore((state) => state.updateUser);

  const group = [BoosterType.DON_X, BoosterType.DURATION_X, BoosterType.INSECTS_X];
  const hasSomeActiveFromGroup = group.some((type) => boosters[type]?.has_active);
  const checkIsBoosterDisabled = (type: BoosterType) => {
    if (!group.includes(type)) {
      return false;
    }

    return !boosters[type]?.has_active && hasSomeActiveFromGroup;
  };

  const getBoosterAmount = (type: BoosterType) => settings[`${type}_price`];

  const handleBuyBooster = async (type: BoosterType) => {
    const amount = getBoosterAmount(type);

    if (!checkBobeBalance(amount)) {
      setSelectedBoosterType(null);
      openNoBobeTokensModal();
      return;
    }

    setLoading(true);
    buyBooster(type)
      .then((updatedUser) => {
        updateUser(updatedUser);
        setSelectedBoosterType(null);
      })
      .catch((error) => {
        showAlert(error?.response?.data?.detail);
      })
      .finally(() => setLoading(false));
  };

  const handleActivateBooster = async (type: BoosterType) => {
    const booster = boosters[type];

    if (checkIsBoosterDisabled(type) || !booster || booster?.has_active) return;

    setLoading(true);
    activateBooster(type)
      .then((updatedUser) => {
        updateUser(updatedUser);
        setSelectedBoosterType(null);
      })
      .catch((error) => {
        showAlert(error?.response?.data?.detail);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <div className={styles.boostersRoot}>
        <div className={styles.overflowContainer}>
          <div className={styles.boostersContainer}>
            {BOOSTERS_LIST.map((type) => (
              <BoosterCard
                key={type}
                disabled={checkIsBoosterDisabled(type)}
                type={type}
                boosterInfo={boosters[type]}
                onClick={() => handleActivateBooster(type)}
                onClickBuy={() => setSelectedBoosterType(type)}
              />
            ))}
          </div>
        </div>
      </div>

      {selectedBoosterType && (
        <BuyBoosterModal
          visible
          loading={loading}
          image={BOOSTERS_ICONS[selectedBoosterType]}
          description={t(`boosters.${selectedBoosterType}.description`) || ''}
          amount={settings[`${selectedBoosterType}_price`]}
          onClose={() => setSelectedBoosterType(null)}
          onClick={() => handleBuyBooster(selectedBoosterType)}
        />
      )}
    </>
  );
};
