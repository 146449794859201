import { useCallback, useEffect, useRef } from 'react';
import { AdsGramErrorType } from 'shared/types/common';
import { ShowPromiseResult, AdController } from 'shared/types/adsgram';

export interface useAdsgramParams {
  blockId: string;
  onReward: (result: ShowPromiseResult) => void;
  onError?: (result: { type: AdsGramErrorType }) => void;
}

export const useAdsgram = ({
  blockId,
  onReward,
  onError = () => {},
}: useAdsgramParams): (() => Promise<void>) => {
  console.log('using ads!!!!!!\n\n\n');
  const AdControllerRef = useRef<AdController | undefined>(undefined);

  useEffect(() => {
    if (blockId && !AdControllerRef.current) {
      const adController = window.Adsgram?.init({
        blockId,
        debug: false,
        debugBannerType: 'FullscreenMedia',
      });

      // // useless shit, doesn't cover all their errors
      // // alerts blocked using modified version of their script at js/adsgram.js
      // if (adController) {
      //   adController.addEventListener('onBannerNotFound', () => {
      //     console.error("Нет баннеров для показа");
      //     onError({ type: AdsGramErrorType.UNKNOWN });
      //   });

      //   adController.addEventListener('onNonStopShow', () => {
      //     console.error("Попытка показать несколько рекламы подряд");
      //     onError({ type: AdsGramErrorType.UNKNOWN });
      //   });

      //   adController.addEventListener('onError', () => {
      //     console.error("Ошибка при показе рекламы");
      //     onError({ type: AdsGramErrorType.UNKNOWN });
      //   });
      // }

      AdControllerRef.current = adController;
    }
  }, [blockId]);

  return useCallback(async () => {
    if (AdControllerRef.current) {
      AdControllerRef.current
        .show()
        .then((result) => {
          // user watch ad till the end
          onReward(result);
        })
        .catch((result: ShowPromiseResult) => {
          // user get error during playing ad or skip ad
          onError({ type: AdsGramErrorType.UNKNOWN });
        });
    } else {
      onError({ type: AdsGramErrorType.NO_SCRIPT });
    }
  }, [onError, onReward]);
};
